@import "./custom.scss";

html {
    font-size: 1rem;
}

body {
    font-family: "Open Sans", sans-serif;

    & h1 {
        font-weight: 400;
    }

    & h4 {
        font-weight: 300;
    }
}

button {
    margin-right: 1rem;

    &.link-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        display: inline;
        padding: 0;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.navbar-brand {
    display: flex;
    align-items: center;
    
    img {
        margin-right: 0.5rem;
    }
}

/*
Define breakpoints and font sizes
Adapted from https://ryanfeigenbaum.com/easy-responsive-typography/
*/
@media (min-width: 640px) and (max-width: 1023px) {
    html {
        font-size: 1.0625rem;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    html {
        font-size: 1.125rem;
    }
}

/* Define max font size and viewport */
@media (min-width: 1280px) {
    html {
        font-size: 1.1875rem;
    }
}
