.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: solid 1px lightgrey;

    a, p {
        margin: 0 5px;
        font-size: calc(10px + 1vmin);
        font-weight: 300;
    }
}